import React from "react";
import {
  FooterContainer,
  ContentFooter,
  MediaLogos,
  Logo,
  Copyright,
  SocialIcons,
  Icon
} from "./styles";

import ThebestLogo from "../../assets/svgs/logos/thebest-logo.svg";
import ThebestAcaiLogo from "../../assets/svgs/logos/thebest-acai-logo.svg";
import AmadelliLogo from "../../assets/svgs/logos/amadelli-logo.svg";
import PeLogo from "../../assets/svgs/logos/pe-logo.svg";
import AmafrutaLogo from "../../assets/svgs/logos/amafruta-logo.svg";
import LinkedInIcon from "../../assets/svgs/linkedin.svg";
import InstagramIcon from "../../assets/svgs/instagram.svg";

const logos = [
  { src: ThebestLogo, alt: "The Best Logo" },
  { src: ThebestAcaiLogo, alt: "The Best Acai Logo" },
  { src: AmadelliLogo, alt: "Amadelli Logo" },
  { src: PeLogo, alt: "Pe Logo" },
  { src: AmafrutaLogo, alt: "Amafruta Logo" },
];

export const Footer = () => {
  return (
    <FooterContainer>
      <ContentFooter>
        <MediaLogos>
          {logos.map((logo, index) => (
            <Logo key={index} src={logo.src} alt={logo.alt} />
          ))}
        </MediaLogos>
        <Copyright>Todos os direitos reservados.</Copyright>
        <SocialIcons>
          <a href="https://www.linkedin.com/company/grupothebest/posts/?feedView=all" target="_blank" rel="noopener noreferrer">
            <Icon src={LinkedInIcon} alt="LinkedIn" />
          </a>
          <a href="https://www.instagram.com/thebestacaiofficial/" target="_blank" rel="noopener noreferrer">
            <Icon src={InstagramIcon} alt="Instagram" />
          </a>

        </SocialIcons>
      </ContentFooter>
    </FooterContainer>
  );
};
