import React from "react";
import HourIcon from "../../assets/svgs/hour-icon.svg";
import GraduateIcon from "../../assets/svgs/requisite-icon.svg";
import Formation from "../../assets/svgs/graduate-icon.svg";
import Travel from "../../assets/svgs/travel-icon.svg";
import Mala from "../../assets/svgs/mala-icon.svg";

import { Container, ContentGeneral, ContentIcons, ContentRequisites, Icon, TopTitle } from "./styles";

export const Requisites = () => {
  return (
    <Container>
      <ContentGeneral>

        <ContentRequisites>
          <TopTitle>Pré-requisitos</TopTitle>

          <span>
            Para fazer parte do nosso programa de trainee, é necessário:{" "}
          </span>
        </ContentRequisites>

        <ContentIcons>
          <div>
            <Icon src={HourIcon} alt="ícone de idade" />
            <span>
              Ter mais de 21 anos
            </span>
          </div>

          <div>
            <Icon src={GraduateIcon} alt="ícone de graduação" />
            <span>
              Estar no último ano de <br />
              graduação ou recem formado <br /> em até 5 anos (Dez/20)
            </span>
          </div>

          <div>
            <Icon src={Formation} alt="ícone de chapéu de graduação" />
            <span>Aberto para todos os cursos</span>
          </div>

          <div>
            <Icon src={Travel} alt="ícone de avião" />
            <span>Disponibilidade para <br /> viajar durante as últimas <br /> etapas do processo seletivo</span>
          </div>

          <div>
            <Icon src={Mala} alt="ícone de mala" />
            <span>Disponibilidade para <br /> mudança de cidade <br /> (Londrina - PR)</span>
          </div>
        </ContentIcons>
      </ContentGeneral>
    </Container>
  );
};
