import styled from 'styled-components';

export const TopTitle = styled.span`
        color:#F9A003;
        text-align: center;
        font-size: 3.2rem;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
        
        @media screen and (max-width: 500px){
            font-size: 1.5rem;
            margin-bottom: .5rem;
        }
`;

export const Title = styled.span<{ color: string }>`
        color: ${({ color }) => color};
        text-align: start;
        font-size: 2.5rem;
        font-weight: 900;
        text-transform: uppercase;

        @media screen and (max-width: 500px){
            font-size: 1.1rem;
        }
`;

export const TopContent = styled.div`
    h4 {
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: 900;
        color: #F9A003;
        text-shadow: 2px 2px 0px #FFFFFF, 5px 4px 0px rgba(0,0,0,0.15);

        @media screen and (max-width: 1024px){
            font-size: 3rem;
        }
        @media screen and (max-width: 700px){
            font-size: 2rem;
        }
        @media screen and (max-width: 500px){
            font-size: 1.5rem;
        }
    }

    div {
        display: flex;
        align-items: end;
    }
`;

export const ContentInfos = styled.div`
    display: flex;
    margin-top: 4rem;
    justify-content: space-between;
    width: 100%;
    
    p{
        color: #FFF;
        font-size: 1.1rem;
        font-weight: 400;
        margin-top: 2rem;

        @media screen and (max-width: 700px){
            flex-direction: column;
            margin-top: 1rem;
            font-size: 1rem;
        }
        @media screen and (max-width: 500px){
            font-size: .9rem;
        }
    }

    @media screen and (max-width: 700px){
        flex-direction: column;
    }
`;

export const ContentLeft = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 5rem;

    @media screen and (max-width: 1024px){
        margin-right: 2rem;
    }

    @media screen and (max-width: 700px){
        width: 100%;
        margin-right: 0;
    }
`;

export const ContentRight = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 5rem;

    @media screen and (max-width: 700px){
        width: 100%;
        margin: 1rem 0 0;
    }
`;