import axios from "axios";

const API_URL = process.env.REACT_APP_API_JANUS_URL;
const apiJanus = axios.create({
  baseURL: API_URL,
  responseType: "json",
});

apiJanus.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default apiJanus;
