import React from "react";

import PlanoSaude from "../../assets/svgs/plano-saude.svg"
import ValeTransporte from "../../assets/svgs/vale-transporte.svg"
import ValeAlimentacao from "../../assets/svgs/vale-alimentacao.svg"
import TotalPass from "../../assets/svgs/totallpass.svg"
import Assiduidade from "../../assets/svgs/assiduidade.svg"
import Salario from "../../assets/svgs/salario.svg"
import Coroa from "../../assets/svgs/coroa.svg"
import Acai from "../../assets/svgs/acai.svg"
import AuxEducacional from "../../assets/svgs/auxilio-educacional.svg"

import { Container, ContainerInscrition, Grid, Step, Number, Title, Label, TopTitle, TextBenefit, BenefitGrid, BenefitItem } from "./styles";

const steps = [
  {
    number: "1.",
    title: "INSCRIÇÃO",
    label: "Esse é o primeiro passo da sua jornada com a gente! Basta preencher o formulário online e nos enviar seu currículo. Queremos conhecer mais sobre você, suas experiências e ambições."
  },
  {
    number: "2.",
    title: "Teste Online",
    label: "Após a inscrição, você será convidado a realizar uma série de testes online. Eles podem incluir raciocínio lógico, inglês e outras competências específicas. Essa etapa nos ajuda a entender suas habilidades técnicas e comportamentais."
  },
  {
    number: "3.",
    title: "Pitch Pessoal",
    label: "Aqui você terá a chance de se apresentar de forma criativa e objetiva. No Pitch Pessoal, queremos que você mostre quem você é, suas motivações e porque acredita que é o candidato ideal para o programa. Será uma apresentação rápida e impactante."
  },
  {
    number: "4.",
    title: "Dinâmica de grupo",
    label: "Nesta etapa, você será desafiado em atividades de grupo. Queremos ver como você trabalha em equipe, lida com problemas e colabora com outras pessoas. A dinâmica de grupo é uma simulação de situações reais que você poderá encontrar no ambiente de trabalho.\nJaneiro - Fevereiro 2025 - Londrina-PR"
  },
  {
    number: "5.",
    title: "Painel de negócios",
    label: "Agora, é a hora de mostrar sua capacidade analítica e visão estratégica. No Painel de Negócios, você e sua equipe trabalharão em um case real, propondo soluções para um desafio empresarial. Esta é uma oportunidade para destacar suas habilidades de liderança e pensamento crítico.\nJaneiro - Fevereiro 2025 - Londrina-PR"
  },
  {
    number: "6.",
    title: "Entrevista Final",
    label: "Chegando à última etapa, você terá a chance de conversar com nossos líderes. A entrevista final é o momento de alinhar expectativas e discutir suas experiências e como você pode contribuir para o sucesso do Grupo The Best. \n Janeiro - Fevereiro 2025 - Londrina-PR"
  },
];

const benefits = [
  { icon: Salario, description: "Salário R$6.000,00" },
  { icon: Acai, description: "Sorvete e Açaí de graça e desconto nos nossos produtos" },
  { icon: ValeAlimentacao, description: "Vale Alimentação" },
  { icon: PlanoSaude, description: "Plano de Saúde" },
  { icon: ValeTransporte, description: "Vale Transporte ou Auxílio locomoção" },
  { icon: AuxEducacional, description: "Auxílio educacional" },
  { icon: TotalPass, description: "Total Pass" },
  { icon: Coroa, description: "O dia do seu jeito no seu mês de aniversário" },
  { icon: Assiduidade, description: "Assiduidade" },
];

export const Infos = () => {
  return (
    <Container id="benefits">
      <ContainerInscrition>
        <Grid>
          {steps.map((step, index) => (
            <Step key={index}>
              <Number>{step.number}</Number>
              <Title>{step.title}</Title>
              <Label>{step.label}</Label>
            </Step>
          ))}
        </Grid>
      </ContainerInscrition>


      <TopTitle>Benefícios</TopTitle>
      <TextBenefit>Bons resultados geram bons retornos, por isso, a vaga oferece:</TextBenefit>

      <BenefitGrid>
        {benefits.map((benefit, index) => (
          <BenefitItem key={index}>
            <img className="icon" src={benefit.icon} alt={`Ícone de ${benefit.description}`} />
            <div className="description">{benefit.description}</div>
          </BenefitItem>
        ))}
      </BenefitGrid>
    </Container>
  );
};
