import React, { ChangeEvent, useState } from 'react';
import axios from 'axios';
import termoDePrivacidade from '../../assets/pdf/LGPD.pdf';
import { Container, Title, Formulario, FormItem, Textarea, CheckboxGroup, Button, ContentButton, BackButton, SuccessCard, BackToHome, ItemFormRow, ItemFormRowPhone } from './styles'
import apiJanus from '../../services/janusApi';

interface FormData {
  nome: string;
  cpf: string;
  dtnasc: string;
  celular: string;
  email: string;
  cep: string;
  rua: string;
  numero: string;
  bairro: string;
  cidade: string;
  estado: string;
  origemlead: string;
  escolhatrilha: string;
  textojornada: string;
  anoconclusao: string;
  textohabilidades: string;
  nivelacademico: string;
  nomecurso: string;
  linklinkedin: string;
  politica: boolean;
  cvanexo: File | null;
}

export const Form = () => {
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    nome: '',
    cpf: '',
    dtnasc: '',
    celular: '',
    email: '',
    cep: '',
    rua: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    origemlead: '',
    escolhatrilha: '',
    textojornada: '',
    textohabilidades: '',
    anoconclusao: '',
    nivelacademico: '',
    nomecurso: '',
    linklinkedin: '',
    politica: false,
    cvanexo: null,
  });

  const handleCepBlur = async () => {
    if (formData.cep.length === 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${formData.cep}/json/`);
        const { logradouro, bairro, localidade, uf } = response.data;
        setFormData({
          ...formData,
          rua: logradouro,
          bairro: bairro,
          cidade: localidade,
          estado: uf,
        });
      } catch (error) {
        alert('CEP inválido ou não encontrado');
      }
    }
  };

  const formatCPF = (value: string) => {
    const digits = value.replace(/\D/g, '');
    return digits.replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  };

  const formatPhone = (value: string) => {
    const digits = value.replace(/\D/g, '');
    if (digits.length <= 10) {
      return digits.replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2');
    } else {
      return digits.replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2');
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type, checked, files } = e.target as HTMLInputElement;
    let formattedValue = value;

    if (name === 'cpf') {
      formattedValue = formatCPF(value);
    } else if (name === 'celular') {
      formattedValue = formatPhone(value);
    }

    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
      if (name === 'politica') setAcceptTerms(checked);
    } else if (type === 'file' && files) {
      setFormData({ ...formData, cvanexo: files[0] });
    } else {
      setFormData({ ...formData, [name]: formattedValue });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (loadingFile) {
      alert("Por favor, aguarde enquanto o arquivo está sendo enviado.");
      return;
    }

    const cleanCPF = formData.cpf.replace(/\D/g, '');
    const cleanCelular = formData.celular.replace(/\D/g, '');

    let urlCvanexo = formData.cvanexo;

    if (formData.cvanexo) {
      const imgToUpload = new FormData();
      imgToUpload.append("file", formData.cvanexo);

      try {
        setLoadingFile(true);
        const { data: { location } } = await apiJanus.post("/trainee/upload", imgToUpload);

        if (!location) {
          throw new Error("Falha no upload do arquivo.");
        }

        urlCvanexo = location;
      } catch (error: any) {
        alert("Ocorreu um erro no upload do arquivo. Por favor, tente novamente.");
        setLoadingFile(false); 
        return;
      } finally {
        setLoadingFile(false); 
      }
    }

    if (!urlCvanexo) {
      alert("Por favor, faça o upload do seu currículo.");
      return;
    }

    const payload = {
      ...formData,
      cpf: cleanCPF,
      celular: cleanCelular,
      anoconclusao: formData.anoconclusao || " ",
      cvanexo: urlCvanexo, 
    };

    try {
      setLoading(true);
      await axios.post(
        'https://webhook.zazos.com/v1/webhook/LZoRm6W8JA2d5ymq72xgX0Odn',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      await apiJanus.post('/trainee', payload);

      setIsSubmitted(true);
    } catch (error) {
      alert(`Erro ao enviar o formulário: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {isSubmitted ? (
        <SuccessCard>
          <h1>Formulário enviado com sucesso!✅</h1>
          <p>Obrigado por seu envio. Em breve entraremos em contato.</p>
          <BackToHome to="/">Retorne à home clicando aqui.</BackToHome>
        </SuccessCard>
      ) : (
        <>
          <div className='top-content'>
            <BackButton to="/">{"Voltar"}</BackButton>
            <Title>Formulário de Cadastro</Title>
          </div>

          <Formulario onSubmit={handleSubmit}>
            <FormItem>
              <label>Nome Completo</label>
              <input name="nome" value={formData.nome} onChange={handleInputChange} required placeholder='Nome Completo' />
            </FormItem>

            <FormItem>
              <label>CPF</label>
              <input type="text" name="cpf" value={formData.cpf} onChange={handleInputChange} required maxLength={14} placeholder='CPF' />
            </FormItem>

            <ItemFormRowPhone>
              <FormItem>
                <label>Data de Nascimento</label>
                <input type="date" name="dtnasc" value={formData.dtnasc} onChange={handleInputChange} required placeholder='DD/MM/AAAA' />
              </FormItem>

              <FormItem>
                <label>Telefone</label>
                <input name="celular" value={formData.celular} onChange={handleInputChange} required placeholder='(99) 99999-9999' maxLength={15} />
              </FormItem>
            </ItemFormRowPhone>

            <FormItem>
              <label>Email</label>
              <input type="email" name="email" value={formData.email} onChange={handleInputChange} required placeholder='Digite seu e-mail' />
            </FormItem>

            <FormItem>
              <label>CEP</label>
              <input name="cep" value={formData.cep} onChange={handleInputChange} onBlur={handleCepBlur} required placeholder='Digite seu CEP' />
            </FormItem>

            <ItemFormRow>
              <FormItem>
                <label>Rua</label>
                <input name="rua" value={formData.rua} onChange={handleInputChange} required placeholder='Rua' />
              </FormItem>

              <FormItem>
                <label>Número</label>
                <input name="numero" value={formData.numero} onChange={handleInputChange} required placeholder='Número do endereço' />
              </FormItem>

              <FormItem>
                <label>Bairro</label>
                <input name="bairro" value={formData.bairro} onChange={handleInputChange} required placeholder='Bairro' />
              </FormItem>
            </ItemFormRow>

            <ItemFormRow>
              <FormItem>
                <label>Cidade</label>
                <input name="cidade" value={formData.cidade} onChange={handleInputChange} required placeholder='Cidade' />
              </FormItem>

              <FormItem>
                <label>Estado</label>
                <input name="estado" value={formData.estado} onChange={handleInputChange} required placeholder='Estado' />
              </FormItem>
            </ItemFormRow>

            <ItemFormRow>
              <CheckboxGroup>
                <label>Como ficou sabendo da oportunidade?</label>
                <div className='flex'>
                  <input type="radio" name="origemlead" value="Instagram" checked={formData.origemlead === 'Instagram'} onChange={handleInputChange} />
                  <label className='select-label'>Instagram</label>
                  <input type="radio" name="origemlead" value="LinkedIn" checked={formData.origemlead === 'LinkedIn'} onChange={handleInputChange} />
                  <label className='select-label'>LinkedIn</label>
                  <input type="radio" name="origemlead" value="Outro" checked={formData.origemlead === 'Outro'} onChange={handleInputChange} />
                  <label className='select-label'>Outro</label>
                </div>
              </CheckboxGroup>

              <CheckboxGroup>
                <label>Qual jornada você quer percorrer?</label>
                <div className='flex'>
                  <input
                    type="radio"
                    name="escolhatrilha"
                    value="Business"
                    checked={formData.escolhatrilha === 'Business'}
                    onChange={handleInputChange}
                  />
                  <label className='select-label'>Business</label>
                  <input
                    type="radio"
                    name="escolhatrilha"
                    value="Industry"
                    checked={formData.escolhatrilha === 'Industry'}
                    onChange={handleInputChange}
                  />
                  <label className='select-label'>Industry</label>
                </div>
              </CheckboxGroup>
            </ItemFormRow>

            <FormItem>
              <label>Nos conte sua jornada até aqui</label>
              <Textarea name="textojornada" value={formData.textojornada} onChange={handleInputChange} required placeholder='Utilize esse espaço para contar sobre você' />
            </FormItem>

            <FormItem>
              <label>Descreva suas maiores habilidades</label>
              <Textarea name="textohabilidades" value={formData.textohabilidades} onChange={handleInputChange} required placeholder='Descreva suas habilidades' />
            </FormItem>

            <FormItem>
              <label>Formação Acadêmica Mais Recente</label>
              <select
                name="nivelacademico"
                value={formData.nivelacademico}
                onChange={handleInputChange}
                required
              >
                <option value="">Selecione uma opção</option>
                <option value="Ensino Médio Completo">Ensino Médio Completo</option>
                <option value="Ensino Superior Cursando">Ensino Superior Cursando</option>
                <option value="Ensino Superior Completo">Ensino Superior Completo</option>
              </select>
            </FormItem>

            {(formData.nivelacademico === "Ensino Superior Cursando") && (
              <FormItem>
                <label>Ano prevista para conclusão</label>
                <input
                  type="month"
                  name="anoconclusao"
                  value={formData.anoconclusao}
                  onChange={handleInputChange}
                  placeholder="Ano prevista para conclusão"
                />
              </FormItem>
            )}

            <FormItem>
              <label>Curso</label>
              <input name="nomecurso" value={formData.nomecurso} onChange={handleInputChange} required placeholder='Informe seu curso' />
            </FormItem>

            <FormItem>
              <label>LinkedIn</label>
              <input name="linklinkedin" value={formData.linklinkedin} onChange={handleInputChange} required placeholder='Insira o link do LinkedIn' />
            </FormItem>

            <FormItem>
              <label>Anexo de Currículo</label>
              <input type="file" name="cvanexo" onChange={handleInputChange} accept='.pdf' required />
            </FormItem>

            <CheckboxGroup>
              <input
                type="checkbox"
                name="politica"
                checked={acceptTerms}
                onChange={(e) => {
                  setAcceptTerms(e.target.checked);
                  setFormData({ ...formData, politica: e.target.checked });
                }}
                required
              />
              <label>
                Concordo com o termo de ciência e à{' '}
                <a
                  href={termoDePrivacidade}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  política de privacidade
                </a>
              </label>
            </CheckboxGroup>

            {loadingFile && "Enviando anexo"}

            <ContentButton>
              <ContentButton>
                <Button
                  type="submit"
                  disabled={!acceptTerms || loadingFile || loading || isSubmitted}
                  backgroundColor='#000'
                  color='#F69F00'
                >
                  {(loading || loadingFile) ? "Enviando..." : "Enviar"}
                </Button>

              </ContentButton>
              <Button type="button" onClick={() => setFormData({ ...formData, cvanexo: null })}>Cancelar</Button>
            </ContentButton>
          </Formulario>
        </>
      )
      }

    </Container >
  )
}
