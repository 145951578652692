import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const SuccessCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #28a745; 
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} 0.5s ease-out; 
  width: 400px; 
  margin: 20px auto; 

  text-align: center;

  @media (max-width: 768px) {
    width: 80%; 
  }
`;

export const Container = styled.div`
  width: 100%;
  min-height: 97vh;
  height: auto;
  background-color: #F9A003;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-size: 24px;
  margin: 0;
  padding: 2rem 5rem;
  box-sizing: border-box;

  .top-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start; 
      gap: 1rem;
    }}

  @media (max-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const BackButton = styled(Link)`
  text-decoration: none;
  color: #000;
  font-size: 1rem;
  position: absolute;
  left: 0;  
  transform: translateX(-100%);  

  @media (max-width: 768px) {
    position: static; 
    transform: none; 
    align-self: flex-start; 
    font-size: 10px;
  }
`;

export const BackToHome = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-size: 13px;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  text-transform: capitalize;
  color: #000;
  margin-bottom: 1.5rem;
  font-weight: 900;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
`;

export const Formulario = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 850px;

  @media screen and (max-width: 768px){
    max-width: 700px;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ItemFormRow = styled.div`
    .flex {
      display: flex;
      gap: .3rem;
    }

    .select-label {
      font-size: 16px; 
      font-weight: normal;

      @media screen and (max-width: 768px){
        font-size: 12px; 
      }
    }

    @media screen and (min-width: 768px) {
        width: 100%;
        display: flex;
        gap: .5rem;
    }
`;

export const ItemFormRowPhone = styled.div`
        width: 100%;
        display: flex;
        gap: .5rem;
`;

export const FormItem = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 14px;
    color: #000;
    margin-bottom: 0.5rem;
    font-weight: 900;
  }

  input,
  select,
  input[type='text'],
  input[type='email'],
  input[type='date'],
  input[type='file'],
  input[type='tel'],
  textarea {
    padding: 0.75rem 1rem;
    height: 2.8rem;
    font-size: 14px;
    color: #000;
    border: 1px solid #ddd;
    border-radius: 13px;
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
    background-color: #fff;
    box-sizing: border-box;
    margin-bottom: 1rem;
    width: 100% !important; 
    font-family: 'Montserrat', sans-serif; 

    &::placeholder {
        font-family: 'Montserrat', sans-serif; 
        color: #aaa;  
   }

    &:focus {
      border-color: #f9a003;
      box-shadow: 0 0 5px rgba(249, 160, 3, 0.5);
    }
  }
`;


export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem; 
  margin-bottom: 1.5rem;

  label {
    font-size: 14px;
    color: #000;
    font-weight: 900;
    display: inline-block;
    margin: 0; 
  }

  input[type='checkbox'] {
    margin: 0; 
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: self-start;
  }
`;


export const Textarea = styled.textarea`
  padding: 1rem;
  font-size: 16px;
  color: #000;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
  resize: vertical;
  min-height: 100px;
  transition: border-color 0.3s, box-shadow 0.3s;
  background-color: #fff;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;

  &:focus {
    border-color: #f9a003;
    box-shadow: 0 0 5px rgba(249, 160, 3, 0.5);
  }

  &::placeholder {
    font-family: 'Montserrat', sans-serif; 
    color: #aaa;  
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContentButton = styled.div`
    display: flex;
    width: 100%;
`;

export const Button = styled.button<{ color?: string; backgroundColor?: string }>`
  padding: 1rem 1.5rem;
  color: ${({ color }) => color || '#000'};
  background-color: ${({ backgroundColor }) => backgroundColor || '#fff'};
  border: none;
  border-radius: 13px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  font-weight: 900;
  font-size: 1.5rem;

  :last-child {
    margin-left: 1rem;
  }

  &:hover {
    opacity: 80%;
  }

  &:disabled {
    background-color: #d3d3d3;
    color: #a9a9a9;
    cursor: not-allowed;
    opacity: 0.6;
  }

  @media screen and (max-width: 768px){
    font-size: 1rem;
  }
`;

