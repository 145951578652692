import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100vw; 
  display: flex;
  position: relative;
  flex-direction: column;
  top: 12%;
  overflow: hidden;

  div {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: end;
    width: 83%;

    @media screen and (max-width: 500px) {
      width: 80%;
    }
  }

  p {
    color: #F9A003 !important;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-transform: uppercase;

    @media screen and (max-width: 500px) {
      font-size: 1rem;
    }
  }

  img {
    height: 100vh;
    width: 100vw; 
    object-fit: cover; 
    position: absolute; 
    top: 10%;
    left: 0;
  }

  a {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 20rem;
    height: 4.5rem;
    bottom: 13%;
    left: 68%;
    font-size: 2rem;
    font-weight: 700;
    text-decoration: none;

    position: absolute;
    background-color: #F9A003;
    border: 0;
    border-radius: 25px;
    color: black;
    text-transform: uppercase;
    cursor: pointer;

    :hover {
      background-color: #F9A003;
      opacity: 70%;
    }

    .inscreva-se-link {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 20rem;
    height: 4.5rem;
    bottom: 13%;
    left: 68%;
    font-size: 2rem;
    font-weight: 700;
    text-decoration: none;

    position: absolute;
    background-color: #F9A003;
    border: 0;
    border-radius: 25px;
    color: black;
    text-transform: uppercase;
    cursor: pointer;

    :hover {
      background-color: #F9A003;
      opacity: 70%;
    }

    @media screen and (max-width: 1300px) {
      width: 15rem;
      height: 5rem;
      bottom: 20%;
      left: 65%;
      font-size: 1.8rem;
    }

    @media only screen and (max-width: 960px) {
      left: 50%;
      transform: translateX(-50%);
      top: 80%;
    }
    @media only screen and (max-width: 500px) {
      left: 50%;
      transform: translateX(-50%);
      width: 10rem;
      height: 3.5rem;
      font-size: 1.1rem;
      top: 83%;
    }}

    @media screen and (max-width: 1300px) {
      width: 15rem;
      height: 5rem;
      bottom: 20%;
      left: 65%;
      font-size: 1.8rem;
    }

    @media only screen and (max-width: 960px) {
      left: 50%;
      transform: translateX(-50%);
      top: 80%;
    }
    @media only screen and (max-width: 500px) {
      left: 50%;
      transform: translateX(-50%);
      width: 10rem;
      height: 3.5rem;
      font-size: 1.1rem;
      top: 83%;
    }
  }
`;
