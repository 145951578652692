import { Button, Container, TextContainer, Overlay } from "./styles";

export const FooterImage = () => {
    return (
      <Container>
        <Overlay /> 
        <TextContainer>
          Aqui começa sua jornada de sucesso!
          <Button to="/form">Inscreva-se agora</Button>
        </TextContainer>
      </Container>
    );
};
