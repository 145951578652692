import styled, { css } from "styled-components";

export const FooterContainer = styled.footer`
  background-color: #000;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  color: white;
  width: 100%;
`;

export const ContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const MediaLogos = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 70%;

  @media (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
`;

export const LogoStyle = css`
  width: 6.5rem;
  height: 6.5rem;

  @media (max-width: 500px) {
    width: 5rem;
    height: 5rem;
  }
`;

export const Logo = styled.img`
  ${LogoStyle}
`;

export const Copyright = styled.div`
  font-size: 1rem;
  text-align: left;
  margin-right: 1rem;

  @media (max-width: 768px) {
    text-align: center;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 500px) {
    gap: 0.5rem;
  }
`;

export const Icon = styled.img`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;
