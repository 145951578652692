import styled, { css } from "styled-components";
import ImageFoguete from '../../assets/svgs/backgrounds/foguete.svg';

export const Container = styled.div`
  background-color: #000;
  background-image: url(${ImageFoguete});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;

  @media only screen and (max-width: 700px) {
    padding: 1rem 2rem;
    height: auto;
  }
`;

export const TopTitle = styled.span`
  color: #F9A003;
  text-align: center;
  font-size: 3.2rem;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  width: 100%;

  @media screen and (max-width: 500px) {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;

export const IconStyle = css`
  width: 4rem;
  height: 4rem;
`;

export const Icons = styled.img`
  ${IconStyle}
`;

export const ContentSteps = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  margin: 0;
`;

export const Grid = styled.div`
  display: grid;
  width: 80%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  justify-items: start;
  gap: 1rem;
  text-align: start;
  margin-top: 2rem;

    @media screen and (max-width: 768px) {
    gap: 1rem;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    justify-items: center;
  }
`;

export const Step = styled.div`
  font-size: 1.2rem;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const Number = styled.div`
  font-size: 5rem;
  color: #F9A003;
  font-weight: 900;

  @media screen and (max-width: 768px) {
    font-size: 3rem;
  }
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0.5rem;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const Date = styled.div`
  font-size: 1.1rem;
  color: #fff;
  margin-top: 0.3rem;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;
