import styled from 'styled-components';
import BannerBottom from '../../assets/svgs/banner_bottom.svg';
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-image: url(${BannerBottom});
  background-size: cover;
  background-position: center;
  height: 100vh;
  padding: 0 4rem;
  position: relative;

  @media (max-width: 768px) {
    height: auto;
    padding: 2rem;
    flex-direction: column;
    text-align: center;
  }
`;

export const Overlay = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
`;

export const TextContainer = styled.div`
    color: black;
    text-transform: uppercase;
    font-size: 3.2rem;
    width: 29%;
    text-align: center;
    font-weight: 600;
    position: relative;
    font-weight: 900;
    z-index: 2; 

  @media (max-width: 1024px) {
    font-size: 2.5rem;
    width: 70%;
    color: black;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    width: 100%;
    margin-bottom: -1rem;
  }
`;

export const Button = styled(Link)`
  background-color: #000;
  color: #f9a003;
  border: none;
  border-radius: 25px;
  width: 100%;
  height: 5rem;
  font-size: 2rem;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
  z-index: 2;
  text-decoration: none; 
  display: flex; 
  align-items: center;
  justify-content: center; 
  margin-top: 3rem;

  &:hover {
    opacity: 50%;
  }

  @media (max-width: 768px) {
    width: 80%;
    height: 4rem;
    font-size: 1.8rem;
  }

  @media (max-width: 500px) {
    width: 100%;
    height: 3.5rem;
    font-size: 1.5rem;
  }
`;