import React from "react";
import ImageI from "../../assets/images/ilustration.png";
import Health from "../../assets/svgs/healthy.svg";
import Alimentation from "../../assets/svgs/alimentation.svg";
import Driver from "../../assets/svgs/drive.svg";
import Clock from "../../assets/svgs/clock.svg";
import Gym from "../../assets/svgs/gym.svg";
import Payment from "../../assets/svgs/payment.svg";
import {
  Icons,
  TopTitle,
  Grid,
  Step,
  Number,
  Title,
  Date,
  Container,
  ContentSteps
} from "./styles";

const steps = [
  { number: "1.", title: "INSCRIÇÃO", date: "01/Nov a 6/Jan" },
  { number: "2.", title: "Teste Online", date: "Dezembro" },
  { number: "3.", title: "Pitch Pessoal", date: "Dezembro" },
  { number: "4.", title: "Dinâmica de grupo", date: "Jan/Fev 2025" },
  { number: "5.", title: "Painel de negócios", date: "Jan/Fev 2025" },
  { number: "6.", title: "Entrevista Final", date: "Jan/Fev 2025" },
  
];

export const Process = () => {
  return (
    <Container>
      <TopTitle>Etapas do processo</TopTitle>
      <ContentSteps>
        <Grid>
          {steps.map((step, index) => (
            <Step key={index}>
              <Number>{step.number}</Number>
              <Title>{step.title}</Title>
              <Date>{step.date}</Date>
            </Step>
          ))}
        </Grid>
      </ContentSteps>
    </Container>
  );
};
