import React from 'react'
import { Container, ContentVideos, TopTitle, VideoSubtitle, VideoTitle, VideoWrapper } from './styles'

const Depoiments = () => {
    return (
        <Container>
            <TopTitle>Depoimentos</TopTitle>

            <ContentVideos>
                <VideoWrapper>
                    <iframe
                        width="450"
                        height="315"
                        src="https://www.youtube.com/embed/a16qoHBGzLY"
                        title="Depoimento 1"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <VideoTitle>Natasha Bastos</VideoTitle>
                    <VideoSubtitle>Trainee 2023 | Gerente de Projetos BI</VideoSubtitle>

                </VideoWrapper>

                <VideoWrapper>
                    <iframe
                        width="450"
                        height="315"
                        src="https://www.youtube.com/embed/KxFKK6vTw5E"
                        title="Depoimento 2"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <VideoTitle>Lucas Nascimento</VideoTitle>
                    <VideoSubtitle>Trainee 2024</VideoSubtitle>
                </VideoWrapper>

            </ContentVideos>
        </Container>
    )
}

export default Depoiments