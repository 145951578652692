import styled, { css } from "styled-components";

export const Container = styled.section`
  background-color: #000;
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media only screen and (max-width: 700px) {
    padding: 1rem 2rem;
    height: auto;
  }
`;

export const TextBenefit = styled.span`
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 900;
  margin-top: 1.3rem;

  @media screen and (max-width: 1024px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 700px) {
    font-size: 0.8rem;
    margin: 1rem;
  }
`;

export const TopTitle = styled.span`
  color: #F9A003;
  text-align: center;
  font-size: 3.2rem;
  font-weight: 900;
  text-transform: uppercase;
  margin: 3rem 0 1.5rem;
  width: 100%;

  @media screen and (max-width: 500px) {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
`;

export const ContainerInscrition = styled.div`
  background-color: #F9A003;
  padding: 1rem 8rem 3rem;
  border-radius: 40px;

  @media screen and (max-width: 700px) {
    padding: 1rem 2rem 2rem;
    border-radius: 20px;
  }
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  text-align: start;
  margin-top: 2rem;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

export const Step = styled.div`
  font-size: 1.2rem;
  color: #000;

  @media screen and (max-width: 700px) {
    font-size: 1.1rem;
  }
`;

export const Number = styled.div`
  font-size: 5rem;
  color: #000;
  font-weight: 900;

  @media screen and (max-width: 700px) {
    font-size: 3.5rem;
  }
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0.5rem;

  @media screen and (max-width: 700px) {
    font-size: 1.3rem;
  }
`;

export const Label = styled.div`
  font-size: 1.1rem;
  color: #000;
  margin-top: 0.3rem;

  @media screen and (max-width: 700px) {
    font-size: 1rem;
  }
`;

export const BenefitGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin: 3rem 0;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
`;

export const BenefitItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;

  & .icon {
    width: 5rem;
    height: 5rem;

    @media screen and (max-width: 700px) {
      width: 4rem;
      height: 4rem;
    }
  }

  & .description {
    font-size: 1rem;
    text-transform: capitalize;
    margin-top: 0.5rem;

    @media screen and (max-width: 700px) {
      font-size: 0.9rem;
    }
  }
`;
