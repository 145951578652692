import styled from "styled-components";

export const Container = styled.div`
  background-color: #F9A01B;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 700px) {
    padding: 3rem;
    height: 100%;
  }

  @media only screen and (max-width: 400px) {
    padding:  5rem 1rem;
  }
`;

export const ContentAbout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding: 4rem;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 0 2rem;
    align-items: baseline;
  }
  @media screen and (max-width: 800px) {
    padding: 0;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 2rem;

  h2 {
    color: black;
    font-size: 3.5rem;
    font-weight: 900;
    line-height: normal;
    overflow: hidden;

    @media screen and (max-width: 500px) {
      font-size: 1.5rem;
    }
  }

  p {
    margin-top: 1rem;
    color: #000;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;

    overflow: hidden;

    @media screen and (max-width: 500px) {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const RightContent = styled.div`
  display: flex;
  width: 50%;

  @media screen and (max-width: 1024px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    padding-bottom: 1rem;
  }
`;

export const Organograma = styled.img`
  width: 40rem;
  @media screen and (max-width: 1024px) {
    width: 20rem;
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 500px) {
     width: 15rem;
     margin-bottom: 0;
  }
`;
