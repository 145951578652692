import React from "react";
import Organon from "../../assets/svgs/about-us-image.svg";
import {
  Container,
  ContentAbout,
  LeftContent,
  Organograma,
  RightContent,
} from "./styles";

export const AboutUs = () => {
  return (
    <Container id="about-us">
      <ContentAbout>
        <LeftContent>
          <h2>Quem somos</h2>

          <p>
            O Grupo The Best nasceu em 2017 pela vontade de três jovens empreendedores, aqui em Londrina/PR. No início, éramos uma pequena semente no mundo dos negócios: um investimento de <b>R$15.000</b>, com uma loja simples de poucos metros quadrados na Avenida JK, mas <b>muita força de vontade</b>.
            <br /><br />
            O tempo passou e estamos cada vez mais próximos do sonho de nos tornarmos um grande jardim: atualmente,
            completando 7 anos de empresa, conseguimos alcançar 10 estados brasileiros e o Paraguai, com mais de 500 lojas, dentre elas franquias e filiais, sendo hoje <b>a maior franqueadora de self-service de açaís e sorvetes do mundo</b>. Botamos fé que um jovem como você têm muito a oferecer e, ao mesmo tempo, aprender no meio disso tudo, levando o lema de <b>fazer o nosso melhor</b> para todos os cantos do Brasil
            <br /><br />
            Realizando sempre o melhor e do nosso jeito!
          </p>
        </LeftContent>

        <RightContent>
          <Organograma src={Organon} alt="Organograma" />
        </RightContent>
      </ContentAbout>
    </Container>
  );
};
