import React from "react";
import IconCheck from "../../assets/svgs/checkicon.svg";
import {
  ContentInfos,
  ContentLeft,
  ContentRight,
  Title,
  TopContent,
  TopTitle,
} from "./styles";
import { Container } from "../../styles/GlobalStyles";

const checklistItems = [
  "Resolução de problemas",
  "Inteligência emocional",
  "Colaboração e apoio",
  "Gestão de pessoas",
  "Pensamento crítico",
  "Liderança nata",
  "Vontade de aprender",
];

export const AboutTrainee = () => {
  return (
    <Container id="about-trainee">
      <TopContent>
        <TopTitle>Sobre o programa trainee</TopTitle>
      </TopContent>

      <ContentInfos>
        <ContentLeft>
          <Title color="#F9A003">O que é?</Title>
          <p>
            Trainee é um cargo oferecido a jovens em início de carreira,
            seja se formando ou recém formados, com um programa de treinamento que busca expandir seus conhecimentos de negócio e te mostrar
            novos horizontes profissionais. <br /><br />Ser trainee é uma opção interessante para quem procura se encontrar no mercado de trabalho enquanto aprende
            tarefas profissionais na prática do dia a dia. A The Best Açaí procura pessoas com muita vontade de aprender, se conectar, realizar e agregar!
          </p>
        </ContentLeft>

        <ContentRight>
          <Title color="#F9A003">Objetivos</Title>

          <p>
            Nosso objetivo é encontrar profissionais que topem aprender sobre as áreas do nosso negócio,
            buscamos pessoas dispostas a aprender, se desenvolver e com determinação para fazerem o seu melhor conosco!
          </p>
        </ContentRight>
      </ContentInfos>
    </Container>
  );
};
