import styled, { css } from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  background-size: cover;
  padding: 6rem 4rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 700px) {
    padding: 3rem;
    margin-top: 0;
  }

  @media screen and (max-width: 1024px) {
    height: 100vh;
    min-height: 100vh;
    padding: 3rem 4rem;
  }

  @media screen and (max-width: 600px) {
    padding: 0 2rem 1rem;
    margin-top: -4rem;
  }

  @media only screen and (max-width: 400px) {
    padding: 1rem;
  }
`;

export const ContentAbout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  h3 {
    color: #F9A003;
    text-align: center;
    font-size: 4rem;
    font-weight: 900;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
      font-size: 3rem;
    }

    @media screen and (max-width: 700px) {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
`;

export const IconsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
  overflow: hidden;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  span {
    color: #000;
    font-size: 1.1rem;
    font-weight: 900;
    width: 75%;
    text-align: center;

    @media screen and (max-width: 1024px) {
      font-size: 0.9rem;
    }
  }

  @media only screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    gap: 1rem;
  }

  @media only screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    margin-top: 1rem;
  }
`;

export const IconStyle = css`
  width: 6rem;
  height: 6rem;

  transition: ease-in-out 0.2s;
  :hover {
    width: 7rem;
    height: 7rem;
  }

  @media screen and (max-width: 1024px) {
    width: 3rem;
    height: 3rem;

    :hover {
      width: 4rem;
      height: 4rem;
    }
  }
`;

export const Icons = styled.img`
  ${IconStyle}
  margin-bottom: 8px;
`;
