import styled from 'styled-components';

export const Container = styled.section`
  background-color: #f9a003;
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media only screen and (max-width: 700px) {
    padding: 1rem 2rem;
    height: auto;
  }
`;

export const TopTitle = styled.span`
  color: #000;
  text-align: center;
  font-size: 3.2rem;
  font-weight: 900;
  text-transform: uppercase;
  margin: 3rem 0 1.5rem;
  width: 100%;

  @media screen and (max-width: 500px) {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
`;

export const ContentVideos = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    width: 100%;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

export const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;

  iframe {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 3px solid white;
    border-radius: 5px;
  }
`;

export const VideoTitle = styled.span`
  margin-top: 0.5rem;
  color: #000;
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
`;

export const VideoSubtitle = styled.span`
  margin-top: 0.5rem;
  color: #000;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
`;