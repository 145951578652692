import styled from "styled-components";

export const Container = styled.div`
  background-color: #f9a003;
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-x: hidden;

  @media only screen and (max-width: 700px) {
    padding: 1rem 2rem;
    height: auto;
  }
`;

export const TopTitle = styled.span`
  color: #000;
  text-align: center;
  font-size: 3.2rem;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  word-wrap: break-word;
  overflow-wrap: break-word;

  @media screen and (max-width: 500px) {
    font-size: 1.5rem;
  }
`;

export const ContentRequisites = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;

  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;

    span {
      text-align: center;
    }
  }
`;

export const ContentGeneral = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1300px;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  span {
    font-size: 1rem;
    word-wrap: break-word;
    overflow-wrap: break-word;

    @media screen and (max-width: 700px) {
      font-size: 0.9rem;
    }
  }
`;

export const ContentIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1.5rem;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  span {
    color: #000;
    font-size: 1rem;
    font-weight: 900;
    margin-top: 1rem;
    word-wrap: break-word;
    overflow-wrap: break-word;

    @media screen and (max-width: 768px) {
      font-size: 0.9rem;
    }

    @media screen and (max-width: 500px) {
      font-size: 0.8rem;
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Icon = styled.img`
  width: 5rem;
  height: 5rem;
  margin-bottom: 0.5rem;

  @media screen and (max-width: 768px) {
    width: 4rem;
    height: 4rem;
  }

  @media screen and (max-width: 500px) {
    width: 3rem;
    height: 3rem;
  }
`;
